import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'app/styles/index.scss';

import { Suspense, useEffect, useMemo } from 'react';
import {
    Navigate, Route, Routes,
} from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { HomePage } from 'pages/HomePage';
import { IntercomPage } from 'pages/IntercomPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { VideoMapPage } from 'pages/MapPage';
import { AuthPage } from 'pages/AuthPage';
import { UserPage } from 'pages/UserPage';
import { ChangeMobilePage, MobilePage } from 'pages/MobilePage';
import { BonusPage } from 'pages/BonusPage';
import { FaqPage } from 'pages/FaqPage';
import { MapRequestHistoryPage } from 'pages/MapRequestHistory';
import { InternetPage } from 'pages/InternetPage';
import { ConstructorPage } from 'pages/ConstructorPage';
import { InternetMoving } from 'pages/InternetMoving';
import { AccountManagePage } from 'pages/AccountManagePage';
import { ProtectedRoutes } from 'widgets/ProtectedRoutes';

import { fetchVariables } from 'entities/variables/model';
import { StateSchema, useAppDispatch } from 'app/providers/StoreProvider';
import { DvrPage } from 'pages/DvrPage';
import { authActions, authSelector } from 'features/Auth/model';
import { useTitle } from 'app/hooks';
import MainLayout from 'app/layouts/MainLayout/MainLayout';
import { Loader } from 'shared/ui/Loader';
import * as Sentry from '@sentry/react';
import { fetchUserInfo } from 'entities/user/model/services';
import { IntercomCallHistoryPage } from 'pages/IntercomCallHistoryPage';
import { UkPage } from 'pages/UkPage';
import { PayHistoryPage } from 'pages/PayHistoryPage';
import { OtherEntrancePage } from 'pages/OtherEntrancePage';
import { fetchLocations } from 'entities/location/model/services/fetchLocations';
import { fetchInternetTariff } from 'entities/InteretTariff/model/services/fetchInternetTariff';
import { PaymentListPage, PaymentPage, PaymentPromo } from 'pages/PaymentPage';

function App() {
    const { isAuth, token } = useSelector(authSelector);
    const title = useTitle();
    const dispatch = useAppDispatch();
    const isLogin = localStorage.getItem('isLogin');
    const locToken = localStorage.getItem('accToken');
    useEffect(() => {
        if (locToken) {
            dispatch(authActions.setAccessToken(locToken));
        }
        // инициализация приложения
        if (token !== 'empty') {
            dispatch(fetchVariables());
            dispatch(fetchUserInfo());
            dispatch(fetchLocations()).then(() => {
                dispatch(fetchInternetTariff());
            });     
        }
    }, [isAuth, dispatch, token]);

    const privateRoutes: Record<string, JSX.Element> = useMemo(
        () => ({
            '/': <HomePage />,
            intercom: <IntercomPage />,
            dvr: <DvrPage />,
            map: <VideoMapPage />,
            user: <UserPage />,
            mobile: <MobilePage />,
            bonus: <BonusPage />,
            faq: <FaqPage />,
            payment: <PaymentPage />,
            pay: <PaymentListPage />,
            requests: <MapRequestHistoryPage />,
            internet: <InternetPage />,
            calls: <IntercomCallHistoryPage />,
            uk: <UkPage />,
            payhistory: <PayHistoryPage />,
            promotion: <PaymentPromo />,
            entrances: <OtherEntrancePage />,
            constructor: <ConstructorPage />,
            accmanage: <AccountManagePage />,
            moving: <InternetMoving />,
            changemobile: <ChangeMobilePage />,
        }),
        [],
    );

    return (
        <div className="App">
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Routes>
                {!isAuth && <Route path="/login" element={<AuthPage />} />}
                {isLogin === '0' && <Route path="/login" element={<AuthPage />} />}
                {!isAuth && <Route path="/" element={<Navigate to="/login" />} />}
                {/* {isShowMobileTariffs && <Route path="/mobile" element={<Navigate to="/" />} />} */}
                <Route path="/" element={<MainLayout />}>
                    {isAuth && <Route path="/login" element={<Navigate to="/" />} />}
                    <Route element={<ProtectedRoutes />}>
                        {Object.entries(privateRoutes).map(([path, element]) => (
                            <Route
                                key={path}
                                path={path}
                                element={<Suspense fallback={<Loader />}>{element}</Suspense>}
                            />
                        ))}
                    </Route>
                    <Route path="*" element={<NotFoundPage />} />
                </Route>
            </Routes>
            <ReduxToastr
                timeOut={2500}
                newestOnTop={false}
                preventDuplicates
                position="top-left"
                // @ts-ignore
                getState={(state: StateSchema) => state.toastr} // This is the default
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
            />
        </div>
    );
}

export default process.env.NODE_ENV === 'production'
    ? Sentry.withProfiler(App)
    : App;
