import React, {
    FC, useState, useEffect, useCallback,
} from 'react';
import { AdaptiveModal } from 'shared/ui/AdaptiveModal/AdaptiveModal';
import { Button, Flex } from 'shared/ui';
import { NewPlus, XClose } from 'shared/assets/icons';
import { TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import { PublicService, UserService } from 'shared/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelector, userActions } from 'entities/user/model';
import { toast } from 'react-toastify';
import cls from './AddAdditionalUidForm.module.scss';

interface AddressResponse {
    address: string;
    oper: string;
    operTitle: string;
}

interface AddAdditionalUidFormProps {
    parentOper?: string;
}

export const AddAdditionalUidForm: FC<AddAdditionalUidFormProps> = () => {
    const [openWindow, setOpenWindow] = useState(false);
    const [contractNumber, setContractNumber] = useState<string>('');
    const [address, setAddress] = useState<string | null>(null);
    const [oper, setOper] = useState<string | null>(null);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profile = useSelector(profileSelector);

    const debouncedSearch = useCallback(
        debounce(async (number: string) => {
            if (number) {
                try {
                    const response = await PublicService.searchAddressByUid(Number(number));
                    const responseData = response.data.data[0] as AddressResponse;
                    if (responseData) {
                        setAddress(responseData.address);
                        setOper(responseData.oper);
                    } else {
                        setAddress(null);
                        setOper(null);
                    }
                } catch (error) {
                    console.error('Ошибка при поиске адреса', error);
                    setAddress(null);
                    setOper(null);
                }
            } else {
                setAddress(null);
                setOper(null);
            }
        }, 500),
        [],
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d{0,6}$/.test(value)) {
            setContractNumber(value);
        }
    };

    useEffect(() => {
        debouncedSearch(contractNumber);
        return () => {
            debouncedSearch.cancel();
        };
    }, [contractNumber, debouncedSearch]);

    const addUidToMe = async () => {
        if (oper && address) {
            try {
                setIsLoadingBtn(true);
                const response = await UserService.addAdditionalUid(oper, Number(contractNumber));
                const resDate = response.data.data.parentUid;
                const paymentData = {
                    uid: resDate,
                    address_full: address,
                    email: profile?.email || '',
                    payment: 500,
                    oper,
                };
                const payData = {
                    uid: resDate,
                    phone: '',
                    oper,
                    defaultPrice: 500,
                };
                localStorage.setItem('paymentData', JSON.stringify(paymentData));
                // @ts-ignore
                dispatch(userActions.setAddedUid(payData));
                setOpenWindow(false);
                toast.success('Адрес успешно добавлен!');
                setTimeout(() => {
                    navigate('/payment');
                }, 1000);                
            } catch (error) {
                toast.error('Ошибка при добавлении адреса');
            } finally {
                setIsLoadingBtn(false);
            }
        }
    };

    return (
        <div className={cls.addUidCard}>
            <div
                className={cls.dopContractCard}
                onClick={() => setOpenWindow(true)}
            >
                <div className={cls.plusSvg}>
                    <NewPlus />
                </div>
                <p>
                    Вы можете оплатить любой лицевой счёт, не привязанный к этому Личному кабинету
                </p>
            </div>
            <AdaptiveModal
                initialFocusRef={false}
                open={openWindow}
                onClose={() => setOpenWindow(false)}
            >
                <div className={cls.wrapper}>
                    <div className={cls.titleWrapper}>
                        <div className={cls.title}>Добавление договора для оплаты</div>
                        <Button
                            variant="noShadow"
                            onClick={() => setOpenWindow(false)}
                            className={cls.closeButton}
                        >
                            <XClose />
                        </Button>
                    </div>
                    <p>Добавление существующего лицевого счета</p>
                    <p>
                        Вы можете произвести оплату за своих близких,
                        для этого Вам необходимо знать номер существующего лицевого счета.
                    </p>
                    <TextField
                        className={cls.textField}
                        placeholder="Номер договора"
                        value={contractNumber}
                        onChange={handleChange}
                        inputProps={{ maxLength: 6 }}
                    />
                    <div className={cls.addressPlaceholder}>
                        {address ? `Проверьте адрес: ${address}` : ''}
                    </div>
                </div>
                {profile?.email && (
                    <Flex align="center" justify="center">
                        <Button
                            className={cls.findButton}
                            disabled={isLoadingBtn || !address || !contractNumber}
                            onClick={addUidToMe}
                        >
                            {isLoadingBtn ? 'Загрузка...' : 'Добавить'}
                        </Button>
                    </Flex>
                )}
            </AdaptiveModal>
        </div>
    );
};
